<script setup lang="ts">
import { ref } from 'vue'
import { Loader } from '@googlemaps/js-api-loader'

withDefaults(
  defineProps<{
    label: string
    modelValue: google.maps.LatLngLiteral | undefined
    address?: string
    required?: boolean
  }>(),
  {
    required: false
  }
)
const emit = defineEmits<{
  (e: 'update:modelValue', value: unknown): void
  (e: 'update:address', value: string | undefined): void
  (
    e: 'change',
    value: {
      lat: number | undefined
      lng: number | undefined
      address: string | undefined
    }
  ): void
}>()

const loader = new Loader({
  apiKey: import.meta.env.VITE_GOOGLE_MAP_API_KEY,
  version: 'weekly',
  libraries: ['places'],
  language: import.meta.env.VITE_GOOGLE_MAP_LANGUAGE || 'en'
})

const autocompleteInput = ref<HTMLInputElement | null>(null)
let autocomplete: google.maps.places.Autocomplete | null = null
loader.importLibrary('places').then((places) => {
  if (autocompleteInput.value) {
    autocomplete = new places.Autocomplete(autocompleteInput.value, {
      types: ['address'],
      fields: ['place_id', 'geometry', 'name', 'formatted_address', 'address_components']
    })
    if (import.meta.env.VITE_GOOGLE_AUTOCOMPLETE_COUNTRIES) {
      autocomplete.setComponentRestrictions({
        country: JSON.parse(import.meta.env.VITE_GOOGLE_AUTOCOMPLETE_COUNTRIES)
      })
    }

    autocomplete.addListener('place_changed', () => {
      if (autocomplete) {
        const place = autocomplete.getPlace()
        if (place.geometry) {
          emit('update:modelValue', {
            lat: place.geometry.location?.lat(),
            lng: place.geometry.location?.lng()
          })
          emit('update:address', place.formatted_address)

          emit('change', {
            lat: place.geometry.location?.lat(),
            lng: place.geometry.location?.lng(),
            address: place.formatted_address
          })
        }
      }
    })
  }
})

const keydownEnter = () => {
  const suggestionSelected = document.getElementsByClassName('pac-item-selected').length
  if (suggestionSelected === 0) {
    const ArrowDownEvent = new KeyboardEvent('keydown', {
      key: 'ArrowDown',
      code: 'ArrowDown',
      keyCode: 40
    })
    const enterEvent = new KeyboardEvent('keydown', { key: 'Enter', code: 'Enter', keyCode: 13 })
    autocompleteInput.value?.dispatchEvent(ArrowDownEvent)
    autocompleteInput.value?.dispatchEvent(enterEvent)
  }
}
</script>

<template>
  <div class="input-wrap">
    <label
      class="label-text"
      :for="label"
    >
      {{ label }}
      <span v-show="required">*</span>
    </label>
    <input
      :id="label"
      ref="autocompleteInput"
      @keydown.enter.self="keydownEnter"
      type="text"
      autocomplete="off"
      role="presentation"
    />
  </div>
</template>

<style lang="scss" scoped>
.input-wrap {
  @apply flex flex-col;
  input {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition:
      border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s;
    @apply text-[#555555] bg-white border border-[#ccd0d2] rounded-2xl text-xl leading-7 py-2.5;
  }

  .label-text {
    @apply text-sm font-bold mb-2;
  }
}
</style>
