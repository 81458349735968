import {
  getLivePromotionBanner,
  getPromotion,
  type PromotionBannerInfoResult,
  type PromotionInfoResult
} from '@/ts/api/promotions'
import { computed, reactive, readonly, ref } from 'vue'
import { STATIC_SOURCES_URL } from '@/ts/stores/os'
import * as pickerType from '@/ts/components/picker/industryPicker/IndustryType'
import collect from 'collect.js'
import type { MediaHasImgKeys } from '@/ts/services/mediaService'
import { getImageByMediaCollectionName } from '@/ts/services/mediaService'
import IndustryCard from '@/ts/components/picker/industryPicker/cards/IndustryCard.vue'
import WishlistCard from '@/ts/components/picker/industryPicker/cards/WishlistCard.vue'
import EntryCard from '@/ts/components/picker/industryPicker/cards/EntryCard.vue'
import type { ItemType as WishListItemType } from '@/ts/components/picker/industryPicker/cards/WishlistCard.vue'
import {
  type getBaseTribeInfoResult,
  getPromotionBanner,
  getTribes,
  searchStores,
  type SearchStoresVariables
} from '@/ts/api/tribes'
import type { searchConditionType } from '@/ts/services/searchStoreService'
import { storeType } from '@/ts/services/variables/countryVariable'
import { useForm } from 'laravel-precognition-vue'
import { useSwal } from '@/ts/services/swal'
import { provideApolloClient } from '@vue/apollo-composable'
import { apolloClient } from '@/ts/plugins/apolloClient'

export const getValueByNameInWebsiteContent = (name: string, data: PromotionInfoResult) => {
  if (data.website_content) {
    const field = collect(data.website_content).where('name', name).first()
    return field?.value
  }
  return null
}

export const getImageByNameInWebsiteContent = (
  name: string,
  data: PromotionInfoResult,
  size: MediaHasImgKeys = 'original_cropped',
  collectionName = 'thumbnail'
) => {
  if (data.website_content) {
    const content = collect(data.website_content).where('name', name).first()
    if (content) {
      return getImageByMediaCollectionName(collectionName, size, content.media_collection)
    }
  }
  return null
}

export const usePromotion = (slug: string, tribeSlug: string) => {
  const state = ref<PromotionInfoResult | null>(null)
  const { onResult, onError } = provideApolloClient(apolloClient)(() => getPromotion(slug))
  const { result: tribesResult, loading: tribeLoading } = provideApolloClient(apolloClient)(() =>
    getTribes({
      tribeType: storeType,
      order: ['state', 'name']
    })
  )
  const ready = ref(false)
  const stores = ref<null | getBaseTribeInfoResult[]>(null)
  const form = useForm<{
    industry: null | string
    wishList: WishListItemType[]
    userDetails: pickerType.SyncDataType
  }>('post', `/api/promotions/${slug}`, {
    industry: null,
    wishList: [],
    userDetails: {
      businessName: '',
      name: '',
      businessAddress: '',
      phoneNumber: '',
      email: '',
      description: '',
      tribeSlug: tribeSlug
    }
  })

  const storeSearch = reactive<{ searchLatLng: undefined; searchAddress: undefined | string }>({
    searchLatLng: undefined,
    searchAddress: undefined
  })

  const searchLocationOptions = ref<SearchStoresVariables>({
    tribe_type: storeType || '',
    address: '',
    lat: 0,
    lng: 0,
    radius: 80
  })
  const { load, onResult: onSearchStoreResult } = provideApolloClient(apolloClient)(() =>
    searchStores(searchLocationOptions)
  )

  const onSearchStores = ({ address, lat, lng }: searchConditionType) => {
    Object.assign(searchLocationOptions.value, {
      address,
      lat,
      lng
    })
    load()
  }

  const tribes = computed(() => {
    if (!tribeLoading.value && tribesResult.value?.tribes) {
      return tribesResult.value.tribes.map((tribe) => ({
        label: tribe.name,
        value: tribe.slug
      }))
    }
    return []
  })

  const industries = computed(() => {
    if (state.value) {
      return [
        {
          title: getValueByNameInWebsiteContent('Industry 1 Name', state.value),
          image: getImageByNameInWebsiteContent(
            'Industry 1 Icon (not selected)',
            state.value,
            'default'
          ),
          hoverImage: getImageByNameInWebsiteContent(
            'Industry 1 Icon (selected)',
            state.value,
            'default'
          )
        },
        {
          title: getValueByNameInWebsiteContent('Industry 2 Name', state.value),
          image: getImageByNameInWebsiteContent(
            'Industry 2 Icon (not selected)',
            state.value,
            'default'
          ),
          hoverImage: getImageByNameInWebsiteContent(
            'Industry 2 Icon (selected)',
            state.value,
            'default'
          )
        },
        {
          title: getValueByNameInWebsiteContent('Industry 3 Name', state.value),
          image: getImageByNameInWebsiteContent(
            'Industry 3 Icon (not selected)',
            state.value,
            'default'
          ),
          hoverImage: getImageByNameInWebsiteContent(
            'Industry 3 Icon (selected)',
            state.value,
            'default'
          )
        },
        {
          title: getValueByNameInWebsiteContent('Industry 4 Name', state.value),
          image: getImageByNameInWebsiteContent(
            'Industry 4 Icon (not selected)',
            state.value,
            'default'
          ),
          hoverImage: getImageByNameInWebsiteContent(
            'Industry 4 Icon (selected)',
            state.value,
            'default'
          )
        }
      ]
    }
    return []
  })

  const wishLists = computed(() => {
    if (state.value && state.value.website_content && form.industry) {
      const selectedIndex =
        industries.value.findIndex((industry) => industry.title === form.industry) + 1

      return collect(state.value.website_content)
        .where('name', `Industry ${selectedIndex} WishList`)
        .first()?.products
    }
    return []
  })

  const componentMap = computed(() => {
    if (state.value) {
      return {
        [pickerType.ComponentType.Industry]: {
          comp: IndustryCard,
          title: getValueByNameInWebsiteContent('Industry Title', state.value) || null,
          subTitle: getValueByNameInWebsiteContent('Industry Subtitle', state.value) || null,
          propsData: {
            options: industries.value,
            industry: form.industry
          },
          on: {
            passValue: (value: { key: string; value: string }) => {
              if (value.key === 'industry') {
                form.industry = value.value
                form.wishList = []
              }
            }
          }
        },
        [pickerType.ComponentType.Wishlist]: {
          comp: WishlistCard,
          title: getValueByNameInWebsiteContent('WishList Title', state.value) || null,
          subTitle: getValueByNameInWebsiteContent('WishList Subtitle', state.value) || null,
          propsData: {
            options: wishLists.value,
            wishList: form.wishList
          },
          on: {
            passValue: (value: { key: string; value: WishListItemType[] }) => {
              if (value.key === 'wishlist') {
                form.wishList.splice(0, form.wishList.length, ...value.value)
              }
            }
          }
        },
        [pickerType.ComponentType.Entry]: {
          comp: EntryCard,
          title: getValueByNameInWebsiteContent('User Detail Title', state.value) || null,
          subTitle: getValueByNameInWebsiteContent('User Detail Title', state.value) || null,
          propsData: {
            storeOptions: tribes.value,
            industry: form.industry,
            wishList: form.wishList,
            userDetails: form.userDetails,
            searchStores: {
              storeSearch,
              onSearchStores,
              stores
            }
          },
          on: {
            passValue: (value: { key: string; value: pickerType.SyncDataType }) => {
              if (value.key === 'userDetails') {
                form.userDetails.businessAddress = value.value.businessAddress
                form.userDetails.businessName = value.value.businessName
                form.userDetails.description = value.value.description
                form.userDetails.email = value.value.email
                form.userDetails.name = value.value.name
                form.userDetails.phoneNumber = value.value.phoneNumber
                form.userDetails.tribeSlug = value.value.tribeSlug
              }
            },
            submitForm: async () => {
              try {
                await form.submit()
                form.reset()
                const swal = useSwal()
                await swal.success('Success', 'form submitted successfully!')
                window.location.reload()
              } catch (e) {
                console.error(e)
              }
            }
          }
        }
      }
    }

    return null
  })

  const pickerOptions = computed(() => {
    if (state.value) {
      return [
        {
          name: 'Industry',
          image: `${STATIC_SOURCES_URL}/picker/Icons-industry.png`,
          hoverImage: `${STATIC_SOURCES_URL}/picker/Icons-industry.png`,
          component: pickerType.ComponentType.Industry
        },
        {
          name: 'Wishlist',
          image: `${STATIC_SOURCES_URL}/picker/Icons-wishlist.png`,
          hoverImage: `${STATIC_SOURCES_URL}/picker/Icons-wishlistb.png`,
          component: pickerType.ComponentType.Wishlist
        },
        {
          name: 'Entry',
          image: `${STATIC_SOURCES_URL}/picker/Icons-entry.png`,
          hoverImage: `${STATIC_SOURCES_URL}/picker/Icons-entryb.png`,
          component: pickerType.ComponentType.Entry
        }
      ]
    }
    return []
  })

  onResult(({ data, loading }) => {
    if (!loading && data && data.promotion) {
      state.value = data.promotion
      ready.value = true
    }
  })

  onSearchStoreResult((response) => {
    if (response.data?.searchStores) {
      stores.value = response.data.searchStores.slice(0, 10)
    }
  })

  onError(async () => {
    await useSwal().error(
      'Error',
      'An error occurred while fetching promotion data, please try again later.'
    )
  })

  return {
    ready: readonly(ready),
    pickerOptions,
    componentMap,
    form,
    storeSearch,
    stores,
    onSearchStores
  }
}

export const usePromotionBanner = (tribeSlug: string) => {
  const { result, loading } = getLivePromotionBanner()
  const { result: tribePromotionStateResult, loading: tribePromotionStateLoading } =
    getPromotionBanner(tribeSlug)
  const banner = computed(() => {
    if (
      !loading.value &&
      result.value?.promotionBanner?.banner &&
      result.value?.promotionBanner?.banner.length > 0
    ) {
      const link = collect<PromotionBannerInfoResult>(result.value.promotionBanner.banner)
        .where('name', 'Promotion URL')
        .first()?.value
      const banner = collect<PromotionBannerInfoResult>(result.value.promotionBanner.banner)
        .where('name', 'Promotion Banner')
        .first()
      if (link && banner) {
        const image = getImageByMediaCollectionName('thumbnail', 'banner', banner.media_collection)
        return [
          {
            idx: 1,
            desktop_image: image ?? '',
            mobile_image: image ?? '',
            heading: '',
            subheading: '',
            link_url: link,
            link_label: null
          }
        ]
      }
    }
    return null
  })

  const tribePromotionState = computed(() => {
    if (!tribePromotionStateLoading.value && tribePromotionStateResult.value?.tribe.contents) {
      const state = collect(tribePromotionStateResult.value.tribe.contents)
        .where('name', 'Promotion Banner')
        .first()?.value
      if (state === 'Disable') {
        return false
      }
    }
    return true
  })

  const ready = computed(() => {
    return !tribePromotionStateLoading.value && !loading.value
  })

  return {
    promotionBanner: banner,
    tribePromotionState,
    ready
  }
}
