<script setup lang="ts">
import { useRoute } from 'vue-router'
import DefaultLayout from '@/ts/layouts/DefaultLayout.vue'
import googleTagManager from '@analytics/google-tag-manager'
import googleAnalytics from '@analytics/google-analytics'
import { initAnalytics } from '@/ts/services/analytics'
import { useSiteHeaderConfigStore } from '@/ts/stores/siteHeaderConfig'
import { ref, watch } from 'vue'
import { getValueByName } from '@/ts/services/websiteContentValue'
import fbp from '@/ts/plugins/analytics/fbp'

const route = useRoute()
const headerConfig = useSiteHeaderConfigStore()

const url = new URL(window.location.href)
if (!url.searchParams.has('_prerender_')) {
  headerConfig.fetch()
}

watch(
  () => headerConfig.isLoaded,
  async () => {
    if (headerConfig.data) {
      const GTM = getValueByName('Google Tag Manager ID', headerConfig.data)
      const GA = getValueByName('Google Analytics Tracking ID', headerConfig.data)
      const PIXEL = getValueByName('Facebook Pixel ID', headerConfig.data)

      const plugins: Record<string, unknown>[] = []

      if (GTM) {
        plugins.push(
          googleTagManager({
            containerId: GTM
          })
        )
      }

      if (GA) {
        plugins.push(
          googleAnalytics({
            measurementIds: [GA]
          })
        )
      }

      if (PIXEL) {
        plugins.push(
          fbp({
            pixelId: PIXEL
          })
        )
      }

      await initAnalytics(plugins)
    }
  }
)
</script>
<template>
  <div class="flex w-full justify-center">
    <DefaultLayout>
      <RouterView :key="route.fullPath"></RouterView>
    </DefaultLayout>
  </div>
</template>
